<template>
	<v-sheet>
		<list-filter
			v-model="show"
			:options="roles"
			title="Filter By Role"
		></list-filter>

		<MwList storeModule="users" :columns="headers" :filtered="includeIds">
		</MwList>
	</v-sheet>
</template>

<script type="text/javascript">
import MwList from "@c/ui/MwList";
import ListFilter from "@c/lists/ListFilter";
export default {
	name: "Users",
	data: () => {
		return {
			filtered: false,
			show: [],
			roles: ["user", "admin", "client", "participant"],
		};
	},
	computed: {
		headers() {
			return [
				{ value: "first_name", text: "First Name", default: true },
				{ value: "last_name", text: "Last Name", default: true },
				{ value: "email", text: "Email", default: true },
				{ value: "role", text: "Role", default: true },
				{ value: "phone", text: "Phone", default: true },
				{ value: "status", text: "Status", default: true },
			];
		},
		users() {
			return this.$store.state.users.data;
		},
		includeIds() {
			const self = this;
			let ids = Object.keys(self.users);
			if (self.show.length) {
				ids = ids.filter((id) => self.show.includes(self.users[id].role));
			}
			return ids;
		},
	},
	components: {
		MwList,
		ListFilter,
	},
};
</script>
